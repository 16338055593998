import React, {useState} from 'react';
import MetricsTableSubRow from "@/js/components/metrics/MetricsTableSubRow";
import {faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const MetricsTableRow = ({row}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [children, setChildren] = useState([]);

    const toggleSubRow = () => {
        setIsExpanded(!isExpanded);
        if (!isExpanded) {
            setChildren([...children, <MetricsTableSubRow row={row} key={children.length} removeChild={removeChild} />]);
        } else {
            setChildren([]);
        }
    };

    const removeChild = index => {
        const newChildren = [...children];
        newChildren.splice(index, 1);
        setChildren(newChildren);
    };


    return (
        <React.Fragment>
            <tr className="border border-white">
                <td className="border border-white p-2">
                    <FontAwesomeIcon onClick={toggleSubRow} icon={isExpanded ? faAngleUp : faAngleDown}/>
                </td>
                <td className="border border-white p-2">{row.id}</td>
                <td className="border border-white p-2">{row.site.url}</td>
                <td className="border border-white p-2">{row.site.ip}</td>
                <td className="border border-white p-2">{row.server_software}</td>
                <td className="border border-white p-2">{row.is_multisite ? 'Yes' : 'No'}</td>
                <td className="border border-white p-2">{row.locale_language}</td>
                <td className="border border-white p-2">{row.php_version}</td>
                <td className="border border-white p-2">{row.plugin_name}</td>
                <td className="border border-white p-2">{row.plugin_version}</td>
                <td className="border border-white p-2">{row.wordpress_version}</td>
                <td className="border border-white p-2">{row.human_readable_created_at_date}</td>
            </tr>
            {isExpanded && (
                <tr className={"border border-white"}>
                    <td className={"border border-white p-2"} colSpan={11}>
                        {children.map((child, index) => (
                            <div key={index}>{child}</div>
                        ))}
                    </td>
                </tr>
            )}
        </React.Fragment>
    );
};
export default MetricsTableRow;
