import React, { useState } from "react";

function printList(list) {
    if (typeof list === 'string') {
        try {
            list = JSON.parse(list);
        } catch (e) {
            return '';
        }
    }

    const [showMore, setShowMore] = useState(false);
    let maxItems = 5;
    let items = [];

    // Check if the input is an array or an object
    if (Array.isArray(list)) {
        // Input is an array
        items = list
            .slice(0, showMore ? list.length : maxItems)
            .map((item, index) => <li key={index}>{item}</li>);
    } else if (typeof list === "object") {
        // Input is an object
        items = Object.keys(list)
            .slice(0, showMore ? Object.keys(list).length : maxItems)
            .map((key, index) => (
                <li key={index}>{list[key]}</li>
            ));
    }

    // Return the list and show more button
    return (
        <div>
            <ul>{items}</ul>
            {showMore || (Array.isArray(list) && list.length > maxItems) || (typeof list === "object" && Object.keys(list).length > maxItems) ? (
                <button style={{ color: 'blue' }} onClick={() => setShowMore(!showMore)}>
                    {showMore ? "Show Less" : "Show More"}
                </button>
            ) : null}
        </div>
    );
}

function SettingsFlags(settings) {
    if (typeof settings === 'string') {
        try {
            settings = JSON.parse(settings);
            settings = settings.settings ? settings.settings : {};
        } catch (e) {
            return '';
        }
    }
    const printSettings = (settings) => {
        const flags = [];
        for (const key in settings) {
            if (settings.hasOwnProperty(key)) {
                const value = settings[key];
                let color = '';
                if (typeof value === 'boolean') {
                    color = value ? 'green': 'red';
                    flags.push(
                        <span key={key} style={{ backgroundColor: color, marginLeft: '5px' }}>
              {key.replaceAll('_', ' ')}
            </span>
                    );
                }
            }
        }
        return flags;
    };

    return <div>{printSettings(settings)}</div>;
}

function printRules(json) {
    if (typeof json === 'string') {
        try {
            json = JSON.parse(json);
        } catch (e) {
            return '';
        }
    }

    return (
        <ul>
            {Object.entries(json.rules).map(([key, value], index) => (
                <li key={key}>
                    {`${index + 1}. ${key} (${value})`}
                </li>
            ))}
        </ul>
    );
}

const MetricsTableSubRow = ({row}) => {
    return (
        <>
            <table className="m-auto border-collapse border-separate border border-gray-400 w-full">
                <thead>
                <tr className="bg-gray-950 text-white">
                    <th className="border border-gray-400 p-2 bg-gray-900">Active Plugins</th>
                    <th className="border border-gray-400 p-2 bg-gray-900">Inactive Plugins</th>
                    <th className="border border-gray-400 p-2 bg-gray-900">Active Wordpress themes</th>
                    <th className="border border-gray-400 p-2 bg-gray-900">Admin email</th>
                    <th className="border border-gray-400 p-2 bg-gray-900">Plugin activation date time</th>
                    <th className="border border-gray-400 p-2 bg-gray-900">Settings</th>
                    <th className="border border-gray-400 p-2 bg-gray-900">Rules</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="border border-gray-400 p-2">{printList(row.active_wordpress_plugins)}</td>
                    <td className="border border-gray-400 p-2">{printList(row.inactive_wordpress_plugins)}</td>
                    <td className="border border-gray-400 p-2">{ row.active_wordpress_theme }</td>
                    <td className="border border-gray-400 p-2">{ row.admin_email }</td>
                    <td className="border border-gray-400 p-2">{ row.plugin_activation_date_time }</td>
                    <td className="border border-gray-400 p-2">{ SettingsFlags(row.plugin_info) }</td>
                    <td className="border border-gray-400 p-2">{ printRules(row.plugin_info)}</td>
                </tr>
                </tbody>
            </table>
        </>
    );
};
export default MetricsTableSubRow;
