import React from 'react';
import RequestHandler from "@/js/RequestHandler";
function Pagination({ filters, setTableDataChanger, currentPage, lastPage, totalPages, nextPage, previousPage }) {
    function handlePageChange(pageNumber) {
        RequestHandler.get(`/metrics/incoming-requests/paginate/`,
            {'page': encodeURIComponent(pageNumber), ...filters},
            (res) => {
                setTableDataChanger(res.data);
            });
    }

    const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

    // Get an array of page numbers to display in the pagination
    const displayPages = [];
    const currentPageIndex = currentPage - 1;

    for (let i = currentPageIndex - 3; i <= currentPageIndex + 3; i++) {
        if (i >= 0 && i < pageNumbers.length) {
            displayPages.push(pageNumbers[i]);
        }
    }

    // Add dots after padding if there are still pages that haven't been displayed
    const firstPage = displayPages[0];
    const lPage = displayPages[displayPages.length - 1];

    if (firstPage > 1) {
        displayPages.unshift('...');
    }

    if (lPage < totalPages) {
        displayPages.push('...');
    }

    return (
        <div>
            <ul className="flex pl-0 list-none rounded my-2">
                <li>
                    <button
                        className={`px-3 py-1 border rounded-l-lg ${
                            currentPage === 1 ? 'bg-red text-white' : 'bg-white text-red border-red'
                        }`}
                        onClick={() => handlePageChange(previousPage)}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>
                </li>

                {displayPages.map((page, index) => (
                    <li key={index}>
                        <button
                            className={`px-3 py-1 border ${
                                page === currentPage ? 'bg-red text-white' : 'bg-white text-red border-red'
                            }`}
                            onClick={() => handlePageChange(page)}
                            disabled={page === '...'}
                        >
                            {page}
                        </button>
                    </li>
                ))}

                <li>
                    <button
                        className={`px-3 py-1 border rounded-r-lg ${
                            currentPage === lastPage ? 'bg-red text-white' : 'bg-white text-red border-red'
                        }`}
                        onClick={() => handlePageChange(nextPage)}
                        disabled={currentPage === lastPage}
                    >
                        Next
                    </button>
                </li>
            </ul>
        </div>
    );
}

export default Pagination;
