import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';
import MetricsTableRow from './MetricsTableRow';
import Pagination from './Pagination';

function MetricsTable({ tableData, setTableData, selectedPHPVersion, selectedWordPressVersion, selectedPluginVersion, selectedIP, selectedURL }) {
    return (
        <div>
            <table className="border-collapse border border-white w-full">
                <thead>
                <tr className="bg-gray">
                    <th scope="col" className="border border-white p-2 text-white">
                        <FontAwesomeIcon icon={faAngleDoubleDown} />
                    </th>
                    <th scope="col" className="border border-white p-2 text-white">
                        #
                    </th>
                    <th scope="col" className="border border-white p-2 text-white">
                        url
                    </th>
                    <th scope="col" className="border border-white p-2 text-white">
                        IP
                    </th>
                    <th scope="col" className="border border-white p-2 text-white">
                        Server Software
                    </th>
                    <th scope="col" className="border border-white p-2 text-white">
                        Multisite
                    </th>
                    <th scope="col" className="border border-white p-2 text-white">
                        Locale Language
                    </th>
                    <th scope="col" className="border border-white p-2 text-white">
                        PHP Version
                    </th>
                    <th scope="col" className="border border-white p-2 text-white">
                        Plugin Name
                    </th>
                    <th scope="col" className="border border-white p-2 text-white">
                        Plugin Version
                    </th>
                    <th scope="col" className="border border-white p-2 text-white">
                        Wordpress Version
                    </th>
                    <th scope="col" className="border border-white p-2 text-white">
                        Date Captured
                    </th>
                </tr>
                </thead>
                <tbody>
                {tableData.data.map((row) => {
                    if (!Object.keys(row).length) {
                        return;
                    }
                    return <MetricsTableRow row={row} key={row.id} />;
                })}
                </tbody>
            </table>
            <Pagination
                filters={{
                    php_version: selectedPHPVersion,
                    wordpress_version: selectedWordPressVersion,
                    plugin_version: selectedPluginVersion,
                    ip: selectedIP,
                    url: selectedURL,
                }}
                setTableDataChanger={setTableData}
                currentPage={tableData.current_page}
                lastPage={tableData.last_page}
                totalPages={Math.ceil(tableData.total / tableData.per_page)}
                nextPage={tableData.current_page + 1 <= tableData.last_page ? tableData.current_page + 1 : tableData.current_page}
                previousPage={tableData.current_page - 1 === 0 ? tableData.current_page : tableData.current_page - 1}
            />
        </div>
    );
}

export default MetricsTable;
