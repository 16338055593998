import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import MetricsTable from "./MetricsTable";
import RequestHandler from "../../RequestHandler";
import SelectBoxFilter from "@/js/components/metrics/SelectBoxFilter";
import CheckboxFilter from "@/js/components/metrics/CheckboxFilter";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleNotch} from '@fortawesome/free-solid-svg-icons';

const MetricsListingPage = () => {
    const [selectedPHPVersion, setSelectedPHPVersion] = useState('');
    const [selectedWordPressVersion, setSelectedWordPressVersion] = useState('');
    const [selectedPluginVersion, setSelectedPluginVersion] = useState('');
    const [selectedIP, setSelectedIP] = useState('');
    const [selectedURL, setSelectedURL] = useState('');
    const [selectedGroupByUniqueUrls, setSelectedGroupByUniqueUrls] = useState(0);
    const [selectedPerPage, setSelectedPerPage] = useState(10);
    const [phpVersions, setPhpVersions] = useState([]);
    const [wordpressVersions, setWordpressVersions] = useState([]);
    const [pluginVersions, setPluginVersions] = useState([]);
    const [IPs, setIPs] = useState([]);
    const [URLs, setURLs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [perPage, setPerPage] = useState([]);

    const [tableData, setTableData] = useState({
        data: [
            {}, {}
        ]
    });

    useEffect(() => {
        RequestHandler.get('api/metrics/filter/options', {}, (res) => {
            setPhpVersions(res.data.php_versions);
            setWordpressVersions(res.data.wordpress_versions);
            setPluginVersions(res.data.plugin_versions);
            setIPs(res.data.ips);
            setURLs(res.data.urls);
            setPerPage(res.data.per_page);
        });
    }, []);

    useEffect(() => {
        setIsLoading(true);
        RequestHandler.get('/metrics/incoming-requests/paginate', {
            php_version: selectedPHPVersion,
            wordpress_version: selectedWordPressVersion,
            plugin_version: selectedPluginVersion,
            ip: selectedIP,
            url: selectedURL,
            group_by_unique_urls: selectedGroupByUniqueUrls,
            per_page: selectedPerPage,
        }, (res) => {
            setTableData(res.data)
            setIsLoading(false);
        });
    }, [selectedPHPVersion, selectedWordPressVersion, selectedPluginVersion, selectedIP, selectedURL, selectedGroupByUniqueUrls, selectedPerPage]);


    return (
        // <div className="flex flex-col items-center justify-center">
        <div className="pt-10 bg-white h-screen mx-auto p-4">
            <div className="flex w-full mb-4 space-x-4">
                <SelectBoxFilter label="IP" options={IPs} selectedOption={selectedIP} onChange={(e) => setSelectedIP(e.target.value)} />
                <SelectBoxFilter label="URL" options={URLs} selectedOption={selectedURL} onChange={(e) => setSelectedURL(e.target.value)} />
                <SelectBoxFilter label="PHP version" options={phpVersions} selectedOption={selectedPHPVersion} onChange={(e) => setSelectedPHPVersion(e.target.value)} />
                <SelectBoxFilter label="WordPress version" options={wordpressVersions} selectedOption={selectedWordPressVersion} onChange={(e) => setSelectedWordPressVersion(e.target.value)} />
                <SelectBoxFilter label="Plugin version" options={pluginVersions} selectedOption={selectedPluginVersion} onChange={(e) => setSelectedPluginVersion(e.target.value)} />
            </div>
            <div className="flex justify-end">
                <CheckboxFilter label="Group By Unique URLs"
                                name="group_by_unique_urls"
                                value="1"
                                selected={selectedGroupByUniqueUrls}
                                onChange={(e) => setSelectedGroupByUniqueUrls(e.target.checked ? e.target.value : 0)} />
                <SelectBoxFilter className="flex flex-row items-center ml-auto mb-4"
                                 labelClassNames="mr-4"
                                 selectClassNames="bg-white border border-gray-300 rounded px-4 py-2 text-gray-800"
                                 label="Per page"
                                 options={perPage}
                                 hasEmptyOption={false}
                                 selectedOption={selectedPerPage}
                                 onChange={(e) => setSelectedPerPage(e.target.value)} />
            </div>

            {!isLoading ? (
                <MetricsTable
                    tableData={tableData}
                    setTableData={setTableData}
                    selectedPHPVersion={selectedPHPVersion}
                    selectedWordPressVersion={selectedWordPressVersion}
                    selectedPluginVersion={selectedPluginVersion}
                    selectedIP={selectedIP}
                    selectedURL={selectedURL}
                />
            ) : (
                <div className="flex items-center justify-center">
                    <FontAwesomeIcon icon={faCircleNotch} spin />
                </div>
            )}

        </div>
    );
};
export default MetricsListingPage;

if (document.getElementById('metrics-listing-page')) {
    const Index = ReactDOM.createRoot(document.getElementById("metrics-listing-page"));

    Index.render(
        <>
            <MetricsListingPage/>
        </>
    )
}
