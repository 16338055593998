import React, {useEffect, useState} from 'react';

const CheckboxFilter = ({ label, name, value, selected, onChange, className, labelClassNames, inputClassNames }) => {
    className = `${className ?? 'flex flex-row items-center mb-4'}`;
    labelClassNames = `${labelClassNames ?? 'ml-2'}`;
    inputClassNames = `${inputClassNames ?? ''}`;
    return (
        <div className={className}>
            <div key={name} className="flex items-center">
                <input
                    type="checkbox"
                    id={name}
                    name={name}
                    value={value}
                    checked={selected}
                    onChange={onChange}
                    className={inputClassNames}
                />
                <label htmlFor={name} className={labelClassNames}>{label}</label>
            </div>
        </div>
    );
}

export default CheckboxFilter;
