import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import MetricsTableRow from "./MetricsTableRow";
import Pagination from "./Pagination";
import RequestHandler from "../../RequestHandler";

const SelectBoxFilter = ({ label, options, selectedOption, onChange, className, labelClassNames, selectClassNames, hasEmptyOption }) => {
    className = `${className ?? 'flex flex-col w-full mb-4'}`;
    labelClassNames = `${labelClassNames ?? 'font-bold mb-2 text-gray-800'}`;
    selectClassNames = `${selectClassNames ?? 'bg-white border border-gray-300 rounded px-4 py-2 mb-2 text-gray-800'}`;
    hasEmptyOption = hasEmptyOption ?? true;

    return (
        <div className={className}>
            <label htmlFor={label} className={labelClassNames}>{label}</label>
            <select
                id={label}
                name={label}
                value={selectedOption}
                onChange={onChange}
                className={selectClassNames}
            >
                {hasEmptyOption ? <option value="">All</option> : ''}
                {options.map((option) => (
                    <option key={option.value} value={option.value} className="text-gray-800">{option.label}</option>
                ))}
            </select>
        </div>

    );
};

export default SelectBoxFilter;
