import React from 'react';

const RequestHandler = {
    trimSlashes: function (str) {
        str = str.startsWith('/') ? str.substr(1) : str;
        str = str.endsWith('/') ? str.substr(0, str.length - 1) : str;
        return str;
    },
    get: async function (uri, params = {}, closure) {
        uri = '/' + this.trimSlashes(uri);
        let queryString = new URLSearchParams(params).toString();
        if (queryString.length) {
            uri += '?' + queryString;
        }

        const response = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.json()
        });

        if ('function' == typeof closure) {
            closure(response)
        }

        return response;
    },
}

export default RequestHandler;
